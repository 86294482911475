<template>
  <div class="reports-page-datatable">
    <el-row :gutter="10">
      <el-col :span="3">
        <p style="font-size: 10.5px; font-style: italic;">Exibindo {{ dataFiltered.length }} registros</p>
      </el-col>
      <el-col :span="14">
        <el-input
          v-model="searchBox"
          size="mini"
          placeholder="Filtrar pelo número do documento, número da carga ou nome do cliente"
        />
      </el-col>

      <el-col :span="4">
        <el-tooltip
          class="box-item"
          effect="dark"
          content="Ao exportar todos os campos são sempre enviados"
          placement="top-start"
        >
        <el-switch
          v-model="showAllColumns"
          active-text="Exibir todas as colunas"
          size="small"
        >
        </el-switch>
      </el-tooltip>
      </el-col>
    </el-row>
    <el-table :data="dataFiltered" style="width: 100%; font-size: 11px; padding: 0;" max-height="800" class="el-table--mini" :border="true">
      <el-table-column fixed sortable prop="cargo_id" label="Carga" width="110">
          <template #default="scope">
            <el-link :href="`${baseurl}/cargos/${scope.row.branch_id}/${scope.row.cargo_id}`" target="_blank" type="primary">{{ scope.row.cargo_id }}</el-link>
          </template>
        </el-table-column>
      <el-table-column sortable resizable prop="branch_name" label="Filial" />
      <el-table-column sortable resizable prop="cargo_status_id" label="Status id" />
      <el-table-column sortable resizable prop="cargo_status" label="Status" />
      <el-table-column v-if="showAllColumns" sortable resizable prop="cargo_created_at" label="Criada em" />
      <el-table-column sortable resizable prop="cargo_started_at" label="Iniciada em" />
      <el-table-column v-if="showAllColumns" sortable resizable prop="cargo_finished_at" label="Finalizada em" />
      <el-table-column v-if="showAllColumns" sortable resizable prop="cargo_created_by" label="Criada por" />
      <el-table-column v-if="showAllColumns" sortable resizable prop="cargo_started_by" label="Iniciada por" />
      <el-table-column v-if="showAllColumns" sortable resizable prop="cargo_finished_by" label="Finalizada por" />
      <el-table-column v-if="showAllColumns" sortable resizable prop="cargo_total_customers" label="Clientes" />
      <el-table-column v-if="showAllColumns" sortable resizable prop="cargo_traveled_time_s" label="Duração(s)" />
      <el-table-column sortable resizable prop="driver_name" label="Motorista" />
      <el-table-column v-if="showAllColumns" sortable resizable prop="checker_name" label="Conferente" />
      <el-table-column sortable resizable prop="truck_id" label="Veiculo" />
      <el-table-column v-if="showAllColumns" sortable resizable prop="truck_start_km" label="Km inicial" />
      <el-table-column v-if="showAllColumns" sortable resizable prop="truck_finished_km" label="Km final" />
      <el-table-column sortable resizable prop="truck_traveled_km" label="Km percorrido" />
      <el-table-column v-if="showAllColumns" sortable resizable prop="truck_traveled_avg" label="Km médio" />
      <el-table-column v-if="showAllColumns" sortable resizable prop="cargo_traveled_time_avg_s" label="Duração médio" />
      <el-table-column v-if="showAllColumns" sortable resizable prop="customer_order" label="Cliente ordem" />
      <el-table-column v-if="showAllColumns" sortable resizable prop="customer_id" label="Cliente id" />
      <el-table-column sortable resizable prop="customer" label="Cliente" />
      <el-table-column v-if="showAllColumns" sortable resizable prop="customer_address" label="Endereço" />
      <el-table-column v-if="showAllColumns" sortable resizable prop="customer_neighborhood" label="Bairro" />
      <el-table-column v-if="showAllColumns" sortable resizable prop="customer_city" label="Cidade" />
      <el-table-column v-if="showAllColumns" sortable resizable prop="customer_state" label="Estado" />
      <el-table-column v-if="showAllColumns" sortable resizable prop="customer_postal_code" label="Codigo Postal" />
      <el-table-column v-if="showAllColumns" sortable resizable prop="customer_phone" label="Telefone" />
      <el-table-column v-if="showAllColumns" sortable resizable prop="customer_route" label="Rota" />
      <el-table-column v-if="showAllColumns" sortable resizable prop="customer_sector" label="Sector" />
      <el-table-column v-if="showAllColumns" sortable resizable prop="customer_zone" label="Zona" />
      <el-table-column sortable resizable prop="customer_status" label="Cliente status" />
      <el-table-column v-if="showAllColumns" sortable resizable prop="canceled_reason" label="Motivo cancelamento" />
      <el-table-column v-if="showAllColumns" sortable resizable prop="canceled_notice" label="Nota cancelamento" />
      <el-table-column v-if="showAllColumns" sortable resizable prop="delivered_doc" label="Entregue para doc" />
      <el-table-column sortable resizable prop="delivered_who" label="Entregue para" />
      <el-table-column v-if="showAllColumns" sortable resizable prop="delivered_km" label="Entregue KM" />
      <el-table-column v-if="showAllColumns" sortable resizable prop="delivered_info" label="Entregue info" />
      <el-table-column sortable resizable prop="delivering_at" label="Entregando em" />
      <el-table-column sortable resizable prop="delivering_checkin_at" label="Entregue checkin" />
      <el-table-column sortable resizable prop="delivered_at" label="Entregue em" />
      <el-table-column sortable resizable prop="order_number" label="Pedido" />
      <el-table-column sortable resizable prop="invoice" label="Documento" />
      <el-table-column sortable resizable prop="invoice_ammount" label="Valor" />
      <el-table-column v-if="showAllColumns" sortable resizable prop="invoice_weight" label="Peso" />
      <el-table-column v-if="showAllColumns" sortable resizable prop="invoice_volumes" label="Volumes" />
      <el-table-column v-if="showAllColumns" sortable resizable prop="invoice_items" label="Itens" />
      <el-table-column v-if="showAllColumns" sortable resizable prop="invoice_status" label="Status" />
      <el-table-column v-if="showAllColumns" sortable resizable prop="invoice_status_reason" label="Status Motivo documento" />
      <el-table-column v-if="showAllColumns" sortable resizable prop="invoice_status_notice" label="Status Nota documento" />
      <el-table-column v-if="showAllColumns" sortable resizable prop="invoice_delivered_at" label="Documento entregue em" />
      <el-table-column v-if="showAllColumns" sortable resizable prop="payments" label="Pagamentos previsto" />
      <el-table-column sortable resizable prop="paymentsEdited" label="Editado" />
      <el-table-column v-if="showAllColumns" sortable resizable prop="paymentsEditedTo" label="Pagamento editado" />
      <el-table-column v-if="showAllColumns" sortable resizable prop="nsu" label="NSU" />
      <el-table-column sortable resizable prop="checked_log" label="Conf log" />
      <el-table-column v-if="showAllColumns" sortable resizable prop="checked_log_by" label="Conferido por" />
      <el-table-column v-if="showAllColumns" sortable resizable prop="checked_log_at" label="Conferido data" />
      <el-table-column sortable resizable prop="checked_fin" label="Conf fin" />
      <el-table-column v-if="showAllColumns" sortable resizable prop="checked_fin_by" label="Conferido por" />
      <el-table-column v-if="showAllColumns" sortable resizable prop="checked_fin_at" label="Conferido data" />
    </el-table>
  </div>
</template>

<script>
import exportJson from '@/helpers/exportXLSX'

export default {
  name: 'report-table-all-cargos-extended',
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    fields: {
      type: Object,
      default: () => {
        return {
          filial_id: 'branch_id',
          filial: 'branch_name',
          carga: 'cargo_id',
          carga_status_id: 'cargo_status_id',
          carga_status: 'cargo_status',
          cargo_criado_em: 'cargo_created_at',
          incio: 'cargo_started_at',
          fim: 'cargo_finished_at',
          duracao: 'cargo_traveled_time_s',
          carga_criada_por: 'cargo_created_by',
          carga_iniciada_por: 'cargo_started_by',
          carga_finalizada_por: 'cargo_finished_by',
          carga_valor_total: 'cargo_total_customers',
          motorista: 'driver_name',
          conferente: 'checker_name',
          veiculo: 'truck_id',
          veiculo_km_iniciado: 'truck_started_km',
          veiculo_km_finalizado: 'truck_finished_km',
          veiculo_km_percorrido: 'truck_traveled_km',
          veiculo_km_medio: 'truck_traveled_avg',
          cliente_ordem: 'customer_order',
          cliente_id: 'customer_id',
          cliente: 'customer',
          cliente_endereço: 'customer_address',
          cliente_cidade: 'customer_city',
          cliente_bairro: 'customer_neighborhood',
          cliente_estado: 'customer_state',
          cliente_codpostal: 'customer_postal_code',
          cliente_telefone: 'customer_phone',
          cliente_rota: 'customer_route',
          cliente_sector: 'customer_sector',
          cliente_zona: 'customer_zone',
          cliente_statis: 'customer_status',
          cliente_status_motivo: 'canceled_reason',
          receptor_doc: 'delivered_doc',
          receptor_nome: 'delivered_who',
          receptor_km: 'delivered_km',
          receptor_info: 'delivered_info',
          cliente_entrando: 'delivering_at',
          cliente_checkin: 'delivering_checkin_at',
          cliente_entregue: 'delivered_at',
          pedido: 'order_number',
          documento: 'invoice',
          valor_total: 'invoice_ammount',
          peso_total: 'invoice_weight',
          volumes_total: 'invoice_volumes',
          total_itens: 'invoice_items',
          status_documento: 'invoice_status',
          status_documento_motivo: 'invoice_status_reason',
          documento_entregue: 'invoice_delivered_at',
          pagamento_previsto: 'payments',
          pagamento_editado: 'paymentsEdited',
          pagamento_editado_para: 'paymentsEditedTo',
          numeros_nsu: 'nsu',
          conferido_logistica: 'checked_log',
          conferido_logistica_por: 'checked_log_by',
          conferido_logistica_data: 'checked_log_at',
          conferido_financeiro: 'checked_fin',
          conferido_financeiro_por: 'checked_fin_by',
          conferido_financeiro_data: 'checked_fin_at',
          duracao_media: 'cargo_traveled_time_avg_s',
          cliente_status_nota: 'canceled_notice',
          status_documento_notice: 'invoice_status_notice',
        }
      },
    },
  },
  data() {
    return {
      showAllColumns: false,
      searchBox: '',
      baseurl: window.location.origin
    }
  },
  computed: {
    dataFiltered() {
      return this.data.filter((d) => {
        return (
          !this.searchBox || this.searchBox
            .toLowerCase()
            .split(' ')
            .every((v) =>
              `${d.customer}${d.cargo_id}${d.invoice}`
                .toLowerCase()
                .includes(v)
            )
        )
      })
    },
  },
  watch: {},
  mounted() {
    this.$emit('loaded')
  },
  methods: {
    DownloadXLS() {
      exportJson.DownloadXLS(this.dataFiltered, this.fields)
    },
    DownloadCSV() {
      exportJson.DownloadCSV(this.dataFiltered, this.fields)
    },
  },
}
</script>
